import $ from 'jquery';
import { onMobileScreen } from './util';

$(function() {
  if ($('.content-section.introduction').length == 0) {
    return;
  }
  function introductionScroll(e: any) {
    const { progress } = e.detail;
    const percentage = 100 - progress * 100 + 30;
    $('.introduction .bg-light').css('transform', `translateY(${percentage}%)`);
    if (percentage > 40) {
      $('.introduction .description').addClass('light');
    }
  }
  onMobileScreen(() => {
    window.removeEventListener('introductionScroll', introductionScroll);
  }, () => {
    window.removeEventListener('introductionScroll', introductionScroll);
    window.addEventListener('introductionScroll', introductionScroll);
  });
});

import $ from 'jquery';

import { formatHTagLines, onMobileScreen } from './util';

$(() => {
  if ($('.two-column').length === 0) {
    return;
  }

  handleTwoColumnLayout();
  onMobileScreen(() => {
    $('.two-column .description').each(function(_index, element) {
      if ($(element).text().replace('&nbsp;', '').trim() == '' && $(element).find('.column-image').length == 0) {
        $(element).hide();
      }
    });
  }, () => {
    $('.two-column .description').show();
  });
});

function handleTwoColumnLayout() {
  formatHTagLines('.two-column', 'h3');
}

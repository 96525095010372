import $ from 'jquery';
import queryString from 'query-string';
import { debounce } from 'chart.js/helpers';

const showMoreLimit = 16;

$(function() {
  if ($('.member-list').length === 0) {
    return;
  }

  let currentTab: string ;

  function checkShowMoreButton() {
    if ($('.member-list .team-member-wrapper:visible').length > showMoreLimit) {
      $('.member-list .team-member-wrapper').each(function(index, element) {
        if (index >= showMoreLimit) {
          $(element).hide();
        }
        $('.show-more-btn').show();
      });
      $('.show-more-btn').on('click', function() {
        if (!currentTab) {
          $('.member-list .team-member-wrapper').show()
        } else {
          $('.member-list .team-member-wrapper').each((_index, element) => {
            if ($(element).data('teamUrl') == currentTab) {
              $(element).show();
            } else {
              $(element).hide();
            }
          });
        }
      });
    } else {
      $('.show-more-btn').hide();
    }
  }

  checkShowMoreButton();

  $('.member-list .teams-filter a').on('click', function(e: any) {
    $('.member-list .teams-filter li').removeClass('active');
    $(e.target).parents('li').addClass('active');
    e.preventDefault();
    e.stopPropagation();
    const { team } = queryString.parse($(this).attr('href')!.split('?').pop() ?? '');
    if (!team) {
      $('.teams-list .team-member-wrapper').show();
      currentTab = '';
    } else {
      $('.teams-list .team-member-wrapper').each((_index, element) => {
        currentTab = team.toString();

        if ($(element).data('teamUrl') == team) {
          $(element).show();
        } else {
          $(element).hide();
        }
      });
    }
    checkShowMoreButton();
  });

  const selectors = ['.team-member-wrapper .team-member .image-wrapper'];
  selectors.forEach(setHeightByMostCommon);

  window.addEventListener('resize', debounce(() => {
    const selectors = ['.team-member-wrapper .team-member .image-wrapper'];
    selectors.forEach(setHeightByMostCommon);
  }, 200));
});

function setHeightByMostCommon(selector: string): void {  
  if ($(selector).length == 0) {
    return;
  }

  const heights = new Map<number, number>();
  $(selector).removeAttr('style');

  setTimeout(() => {
    $(selector).each(function(_i, e) {
      const elementHeight = $(e).outerHeight() || 0;

      if (elementHeight > 0) {
        heights.set(elementHeight, (heights.get(elementHeight) || 0) + 1);
      }
    });

    let mostCommonHeight = 0;
    let maxCount = 0;
    heights.forEach((count, height) => {
      if (count > maxCount) {
        maxCount = count;
        mostCommonHeight = height;
      }
    });

    $(selector).height(mostCommonHeight);
  }, 500);
}
import $ from 'jquery';

declare var window: any;

$(document).ready(function() {
  function equalizeHeight(selector: string) {
    let maxHeight = 0;

    $(selector).each((_index, element) => {
      var height = $(element).innerHeight() || 0;
      if (height > maxHeight) {
        maxHeight = height;
      }
    });
    $(selector).innerHeight(maxHeight);
  }

  window.equalizeHeight = equalizeHeight;

  equalizeHeight('.comparison-table .column-header, .comparison-table .top-background');
  setTimeout(() => {
    equalizeHeight('.comparison-table .column-header, .comparison-table .top-background');
  }, 100);
  equalizeHeight('.comparison-table .column-description');
  equalizeHeight('.comparison-table .accordion-item .accordion-header');

  $('.accordion-header').on('click', function(e: any) {
    const $parent = $(e.target).parents('.accordion-item');
    const index = $parent.data('accordionIndex');
    if ($(`.accordion-item-${index}`).length > 0) {
      $(`.accordion-item-${index}`).toggleClass('open');
    } else {
      $parent.toggleClass('open');
    }
  });
});

import $ from 'jquery';
import textFit from 'textfit';

window.textFit = textFit;

function resetAnimation(el: HTMLElement) {
  el.style.animation = 'none';
  el.offsetHeight;
  el.style.animation = '';
}

function applyItemAnimation() {
  const selector = '.large-hero-banner .animated-item';
  let showingElementIndex = 0;
  let targetSelector;
  const elements = $(selector);
  const interval = 2000;

  if (elements.length === 0) return;

  elements.each((_index, el) => {
    let formattedText = '';
    const text = $(el).text();
    const lines = text.split("|");
    const maxFontSize = parseInt($(el)?.children().css('font-size') ?? '80', 10);

    if (lines.length === 0) return;
    const tag = $(el).children().prop('nodeName');
    const classes = $(el).children().attr('class');

    let index = 0;
    const delay = 100;

    if (tag != 'IMG') {
      for (const line of lines) {
        const animationDelay = `${index * delay}ms`;
        const formatedLine = ` <${tag} class="animating light ${classes}" style="--animation-delay: ${animationDelay}">
            <span>${line.split(' ').join('</span> <span>')}</span></${tag}>`;
        formattedText = `${formattedText}${formatedLine}`;
        index++;
      }

      $(el).html(formattedText);
      textFit(el, { maxFontSize, widthOnly: true });
      const elPrev = $(el).prev(selector);
      if (elPrev.length > 0) {
        $(el).hide();
      }
    }
  });

  targetSelector =
    `${selector} .animating span,
    ${selector} img`;

  if (!$(targetSelector).parents('.large-hero-banner').hasClass('with-animated-titles')) {
    return;
  }

  setInterval(() => {
    $(targetSelector).each((_index, el) => {
      resetAnimation(el);
      if ($(selector).hasClass('fade-out')) {
        $(el).css('transform', 'translateY(100%)');
      } else {
        $(el).css('transform', 'translateY(0)');
      }
    });
    $(selector).toggleClass('fade-out');
  }, interval);

  setInterval(() => {
    showingElementIndex = 0;

    $(".large-hero-banner .animated-item-wrapper").each((_i, e) => {
      const animatedItemChildren = $(e).find('.animated-item');

      if(animatedItemChildren.length > 1) {
        showingElementIndex++;
        showingElementIndex = showingElementIndex % animatedItemChildren.length;

        $(animatedItemChildren).each((index, el) => {
          if (index == showingElementIndex) {
            $(el).show();
          } else {
            $(el).hide();
          }
        });
      }
    });
  }, interval * 2);
}

$(() => {
  if ($('.large-hero-banner').length === 0) {
    return;
  }

  applyItemAnimation();
  let resizeTimeout: any;
  $(window).on('resize', () => {
    if (resizeTimeout) {
      clearTimeout(resizeTimeout);
    }
    resizeTimeout = setTimeout(() => {
      $('.large-hero-banner .animated-title, .large-hero-banner .animated-subtitle').each((_, el) => {
        if ($(el).css('display') !== 'none') {
          textFit(el, { maxFontSize: parseInt($(el).css('font-size') ?? '16', 10), widthOnly: true });
        }
      });
    }, 300);
  });
});

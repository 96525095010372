import $ from 'jquery';
import { debounce } from './debounce';
import { isMobile } from './util';

let circleIndex = 0;
function orbit(selector: string, rotation: number = 0, distance = 160) {
  let container = $(selector);
  $(container).css('transform', `none`);
  let canvas = container.find('canvas').get(0) as HTMLCanvasElement;
  if (!canvas) {
    canvas = document.createElement('canvas');
    container.append(canvas);
  }
  let ctx = canvas.getContext('2d')!;

  canvas.width = $(container).width() || 0;
  canvas.height = $(container).height() || 0;
  let hw = canvas.width/2;
  let hh = canvas.height/2;
  let width = canvas.width / 2 - 5;
  let height = canvas.height / 2 - 5;
  ctx.transform(1, 0, 0, -1, hw, hh);
  const color = container.css('--text-color')!;
  const primaryColor = container.css('--primary-color')!;

  if (rotation) {
    $(container).css('transform', `rotate(${rotation}deg)`);
  }
  const drawCircle = function(ctx: CanvasRenderingContext2D, color: string, radius: number, px: number, py: number) {
    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.arc(px, py, radius, 0, 2 * Math.PI);
    ctx.fill();
  };

  function drawPlanets(count = 3) {
    let boundary = width - .001;	// Boundary for a
    let u = 1;			// Gravitional parameter (M*6.67e-11)
    let T = 2 * Math.PI * width ** 1.5 / u ** 0.5;		// Orbital period
    let dt = T / 1000;								// width slice of time

    let planets = Array.from({length: count}, (_v, i) => ({
      directionY: 1,
      lastPx: (i - 1) * boundary,
      velocityX: 0,
      px: 0,
      py: 0,
      radius: 3,
      index: circleIndex++,
    }));

    function next() {
      if (!isMobile()) {
        window.requestAnimationFrame(next);
      }

      ctx.clearRect(-canvas.width, -canvas.height, canvas.width * 2, canvas.height * 2);

      planets.forEach((planet, _i) => {
        let { directionY, lastPx, velocityX, px, py, radius, index } = planet;
        px = lastPx + velocityX * dt;
        if(directionY * px > boundary) {
          px = directionY * boundary;
          directionY = -directionY;
        }
        py = directionY * height * (1 - px * px / width / width) ** 0.5;
        velocityX = py / height * (u * width / ((px - distance) ** 2 + py * py)) ** 0.5;
        lastPx = px;
        Object.assign(planet, { directionY, lastPx, velocityX, px, py, radius });

        const active = index == $(container).data('activeCircle');

        drawCircle(ctx, active ? primaryColor : color, active ? radius * 2 : radius, px, py);

        ctx.strokeStyle = color;
        ctx.setLineDash([3, 3]);
        ctx.beginPath();
        ctx.ellipse(0, 0, width, height, 0, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.stroke();
      });
    }
    next();
  }

  drawPlanets();
}

$(() => {
  if ($('.ecosystem .ellipse').length > 0) {
    orbit('.ecosystem .ellipse-1', -15, 200);
    orbit('.ecosystem .ellipse-2');
    window.addEventListener('resize', debounce(() => {
      orbit('.ecosystem .ellipse-1', -15, 200);
      orbit('.ecosystem .ellipse-2');
    }));
  }

  function preload() {
    const backgroundImages = $('.ecosystem .butterfly').data('background');
    if (!backgroundImages) return;

    for (var i = 0; i < backgroundImages.length; i++) {
      const preloadLink = `<link rel="preload" href="${backgroundImages[i]}" as="image">`;
      $(document.head).append(preloadLink);
    }
  }

  preload();

  $('.ecosystem .link').each((index, element) => {
    $(element).on('mouseenter', () => {
      const backgroundImages = $('.ecosystem .butterfly').data('background');
      const backgroundImage = backgroundImages[index];
      if (backgroundImage) {
        $('.ecosystem .butterfly').css('background-image', `url(${backgroundImage})`);
        $('.ellipse-1').data('activeCircle', index);
        $('.ellipse-2').data('activeCircle', index);
      }
    });
  });
});

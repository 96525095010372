import $ from 'jquery';
import { onDesktopScreen } from '../util';

$(function(){
  if ($('.page-locations-detail').length == 0) {
    return;
  }

  onDesktopScreen(() => {
    let container = $('.our-clubs-section');
    if (container.length > 0) {
      equalizeHeight('.our-clubs-section');
    }

    addBgCharcoal('.our-clubs-section');
  });
});

function equalizeHeight(selector: string): void {
  const container = $(selector);
  const leftElements = container.find('.col-left .umb-block-list').children();
  const rightElements = container.find('.col-right .umb-block-list').children();

  const maxElements = Math.max(leftElements.length, rightElements.length);

  for (let i = 0; i < maxElements; i++) {
    const leftElement = leftElements.eq(i);
    const rightElement = rightElements.eq(i);

    const heightLeft = leftElement.innerHeight() || 0;
    const heightRight = rightElement.innerHeight() || 0;

    const maxHeight = Math.max(heightLeft, heightRight);

    if (leftElement.length) leftElement.height(maxHeight);
    if (rightElement.length) rightElement.height(maxHeight);
  }
}

function addBgCharcoal(selector: string): void {
  const container = $(selector);
  const bgSandstoneHeight = container.find('.bg-sandstone').height() || 0;
  const newBgCharcoalHeight = bgSandstoneHeight * 0.40;
  const newBgCharcoalDiv = $('<div class="bg-charcoal absolute top-0 left-0 right-0"></div>');
  newBgCharcoalDiv.css({
    'height': newBgCharcoalHeight,
    'z-index': 0
  });
  container.find('.bg-sandstone').before(newBgCharcoalDiv);
}


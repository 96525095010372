import $ from 'jquery';
import { convertTextToAlias, onMobileScreen } from '../../scripts/util';

declare var window: any;
$(function(){
  if($('.form-list').length === 0) {
    return;
  }

  onMobileScreen(() => {
    $('.content-page-index').css({'align-items': 'flex-start'});
  });

  setPaddingBottom();
  applyCheckIcon();

  setTimeout(() => {
    setFormTitleHeight();
  });

  $('.btn-continue').on('click', function(e) {
    e.preventDefault();
    const totalSections = $('.form-info').length;
    const checkedSections = $('.form-info.checked').length;
    var currentActive = $('.progress-steps li.active');
    var nextStep = currentActive.next('li');

    if (nextStep.length > 0) {
      const targetUrl = (checkedSections === totalSections)
      ? $(nextStep).find('a').attr('href')
      : $(this).attr('href');
      window.location.href = targetUrl;
    }
  });
});

function setFormTitleHeight() {
  var maxHeight = 0;

  $('.form-list .umb-block-list h3').each(function() {
    var h3Height = $(this).height() || 0;

    if (h3Height > maxHeight) {
        maxHeight = h3Height;
    }
  });

  $('.form-list .umb-block-list h3').height(maxHeight);
}

function setPaddingBottom() {
  const navTopHeight = $('.top-nav-link').outerHeight() || 0;
  const navBottomHeight = $('.nav-index').outerHeight() || 0;
  $('.form-list .umb-block-list').css({
    'padding-top': navTopHeight + 'px',
    'padding-bottom': navBottomHeight + 'px'
  });
}

function applyCheckIcon() {
  const storedData = localStorage.getItem('formData');
  const parsedData: { [key: string]: any } = storedData ? JSON.parse(storedData) : {};

  if (Object.keys(parsedData).length === 0) {
    return;
  }

  $('[data-form-title]').each((_index, element) => {
    const alias = convertTextToAlias($(element).data('form-title') || '');

    if (typeof parsedData[alias] !== 'undefined' && Object.keys(parsedData[alias]).length > 0) {
      $(element).addClass('checked');
    }
  });
}


import $ from 'jquery';

import TextAnimationManager from './text-animation';
import { isMobile, onDesktopScreen } from './util';
import MouseFollower from "mouse-follower";

import gsap from './gsap';

MouseFollower.registerGSAP(gsap);

$(function(){
  if ($('.small-hero-banner').length === 0) {
    return;
  }

  const showSingleElementOnly = true;
  const maxFontSize = parseInt($('.animated-title').css('font-size') ?? '80', 10);
  const animationManager = new TextAnimationManager();
  animationManager.startTextAnimations(
    '.small-hero-banner .animated-title',
    'h1',
    maxFontSize,
    true,
    showSingleElementOnly
  );

  initBannerScrollEffects();
});

// function hoverOnBannerVideo() {
//   const cursor = new MouseFollower({
//     container: '.small-hero-banner',
//     hiddenState: 'hidden',
//     visible: false,
//     hideOnLeave: false,
//   });
//   $('.small-hero-banner .banner-video').off('mouseenter').on('mouseenter', function() {
//     cursor.show();
//     cursor.setImg('/assets/play.svg');
//   }).off('mouseleave').on('mouseleave', function() {
//       cursor.hide();
//   });
// }

function initBannerScrollEffects() {
  const bannerVideo = $('.small-hero-banner .banner-video');
  if (!isMobile()) {
    if (bannerVideo.length > 0) applyScrollAnimation(bannerVideo);
  }

  onDesktopScreen(() => {
    if (navigator.maxTouchPoints == 0) {
      // hoverOnBannerVideo();
    }
  });
}

function applyScrollAnimation(selector: JQuery<HTMLElement>) {
  if ($(selector).length == 0) {
    return;
  }

  const tl = gsap.timeline({ paused: true });
  tl.to(selector, {
    bottom: 0,
    left: 0,
    duration: 2.5,
    ease: "power2.out",
    transformOrigin: "bottom right",
    scrollTrigger: {
      trigger: '.small-hero-banner',
      start: 'top',
      scrub: true,
      pin: true,
    }
  }, 0);

  function smallHeroBannerScroll(e: any) {
    const { progress } = e.detail;

    /* Update timeline progress */
    tl.progress(progress);
  }
  window.removeEventListener("smallHeroBannerScroll", smallHeroBannerScroll);
  window.addEventListener("smallHeroBannerScroll", smallHeroBannerScroll);
}

import $ from 'jquery';
import textFit from 'textfit';
import { isMobile } from './util';

$(() => {
  if(isMobile()) {
    $('.text-fit').each((_index, element) => {
      try {
        textFit(element, {multiLine: true});
      } catch (e) {
        // console.error(e);
      }
    });
  }
});

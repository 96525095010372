import $ from 'jquery';

$(() => {
  $('.large-image-banner-multiple .links a').on('click', function(e) {
    e.preventDefault();
    e.stopPropagation();
    var $wrapper = $(e.target).parents('.link-wrapper');
    var $target = $($wrapper.data('target'));
    if ($target.length > 0) {
      $(e.target).parents('.large-image-banner-multiple').find('.layer').hide();
      $target.show();
    }
  });
});

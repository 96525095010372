import $ from 'jquery';
import { isMobile } from './util';

$(() => {
  $('.header .group.has-sub-menu .chevron-icon').on('click', (e) => {
    if (!isMobile()) {
      return;
    }

    let height = 0;
    const el = $(e.target);
    const parentMenu = $(el).closest('.has-sub-menu');
    const subMenu = parentMenu.find('> .sub-menu');

    if (parentMenu.hasClass("sub-menu-opened")) {
      parentMenu.removeClass("sub-menu-opened");
      subMenu.css({
        "max-height": "0"
      })
    } else {
      setTimeout(function() {
        subMenu.css({
          "max-height": "none",
          position: "absolute",
          visibility: "hidden"
        })
  
        height = subMenu.height() || 0;
  
        subMenu.css({
          "max-height": "0",
          position: "relative",
          visibility: "visible"
        });
      }, 25);
  
      setTimeout(function() {
        $('.has-sub-menu').not(parentMenu).removeClass("sub-menu-opened");
        el.closest('ul').find('li.has-sub-menu').find("> .sub-menu").css(
          "max-height", "0"
        );
        parentMenu.addClass("sub-menu-opened");
        subMenu.css("max-height", height);        
      }, 50);
    }
  });
  $('.mobile-menu-toggle').on('click', () => {
    $('.header').toggleClass('menu-opened');
    $('.mobile-menu').addClass('mobile-menu-animated');
    $('.mobile-menu').toggleClass('hidden-horizon').toggleClass('mobile-menu-opened');

    if(!($('.mobile-menu').hasClass("mobile-menu-opened"))) {
      const timeOut = setTimeout(() => {
        $('.mobile-menu').removeClass('mobile-menu-load');
        $('.sub-menu').removeAttr('style');
        $('.has-sub-menu').removeClass("sub-menu-opened");
        clearTimeout(timeOut);
      }, 500);
    } else {
      $('.mobile-menu').addClass('mobile-menu-load');
    }
  });
  $('.header .back-btn').on('click', (e) => {
    e.preventDefault();
    e.stopPropagation();
    $('.header').removeClass('menu-opened');
    $(e.target).parents('.has-sub-menu').removeClass('sub-menu-opened');
  });

  if ($('.page-home').length > 0) {
    $('header .menu-items a').on('click', function(e) {
      var targetId = $(this).attr('href');
      if (targetId?.startsWith('/#')) {
        e.preventDefault();
        const offsetY = isMobile() ? $('.header').outerHeight() : 0;

        if (isMobile()) {
          $('.mobile-menu-toggle').trigger('click');
        }

        if (targetId && targetId !== '#') {
          targetId = targetId.replace("/", "");

          gsap.to(window, {
            scrollTo: {
              y: targetId,
              offsetY: offsetY
            },
            duration: 1,
            ease: "power2.inOut"
          });
        }
      }
    });
  }
});

import $ from 'jquery';
import { isMobile } from './util';

$(function() {
  $('a').on('click', function(e) {
    var targetId = $(this).attr('href');
    if (targetId?.startsWith('#')) {
      e.preventDefault();
      const offsetY = isMobile() ? $('.header').outerHeight() : 0;

      if (targetId && targetId !== '#') {
        gsap.to(window, {
          scrollTo: {
            y: targetId,
            offsetY: offsetY
          },
          duration: 1,
          ease: "power2.inOut"
        });
      }
    }
  });
});

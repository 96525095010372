import {
    computePosition,
    flip,
    offset,
    shift,
} from '@floating-ui/dom';
import $ from 'jquery';
import { onMobileScreen } from './util';

$(() => {
  if (('.tooltip').length == 0) {
    return;
  }
  $('.tooltip').each((_index, tooltip) => {
    const button = tooltip;
    const container = tooltip.querySelector('.tooltip-container');
    if (button && container) {
      setupTooltip(button as HTMLElement, container as HTMLElement);
    }
  });
});

function setupTooltip(button: HTMLElement, tooltip: HTMLElement) {
  function update() {
    computePosition(button, tooltip, {
      // ... options ...
      placement: $(button).data('placement') || 'top',
      middleware: [
        offset(6),
        flip(),
        shift({padding: 5}),
      ],
    }).then(({x, y}) => {
      Object.assign(tooltip.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  }

  function checkClickOutside(event: any) {
    const isClickInside = tooltip.contains(event.target as any)

    if (!isClickInside) {
      hideTooltip();
      console.log('hide tooltip click outside');
    }
  }

  function showTooltip() {
    $('.tooltip-container').hide();
    tooltip.style.display = 'block';
    update();
    setTimeout(() => {
      document.addEventListener('click', checkClickOutside);
    }, 0);
  }

  function hideTooltip() {
    $('.tooltip-container').hide();
    tooltip.style.display = '';
    document.removeEventListener('click', checkClickOutside);
  }

  function toggleTooltip() {
    if (tooltip.style.display == '') {
      showTooltip();
    } else {
      hideTooltip();
    }
  }

  onMobileScreen(() => {
    $(button).off('mounseenter').on('mouseenter', showTooltip);
    $(button).off('mounseleave').on('mouseleave', hideTooltip);
    $(button).off('focus').on('focus', hideTooltip);
    $(button).off('blur').on('blur', hideTooltip);
  }, () => {
    $(button).off('click').on('click', toggleTooltip);
  });
}

import { sha256 } from '@noble/hashes/sha2';

/**
 * LLM Assistants
 *
 * Data Analysis Assistant: a transactional LLM that takes question and answer data from the user and returns an array of insights that can be printed to the results page.
 * More About You Assistant: A chat-based LLM that will step through with the user about their health struggles, as a transactional LLM that summarises the results of the chat.
 * Product Assistant: A transactional LLM that takes the output of the Data Analysis Assistant and the More About You Summary, and returns a list of products that would suit the user.
 */
//const apiDomain = 'http://localhost:3000';
const apiDomain = 'https://monarch-assistants-236165475d97.herokuapp.com';

function makeKey(body: string) {
  const uint8array = sha256(body);
  const key = uint8array.map((x: any) => x.toString(16).padStart(2, '0')).join('');
  return key;
}

/**
* Used to create a transactional call to an assistant
* Valid for data-analysis-assistant and products-assistant, or the more-about-you-assistant when issuing a SUMMARISE request
* @param threadId only required for more-about-you-assistant when issuing a SUMMARISE request
* Returns a promise
* */
export async function transactionalAssistantMessage(assistantName: string, messageJSONString: string, threadId: string = '') {
  if (assistantName !== 'data-analysis-assistant' &&
    assistantName !== 'products-assistant' &&
    assistantName !== 'more-about-you-assistant' &&
    assistantName !== 'goals-assistant'
  ) return Promise.reject('Transactional assistant not found');

  const cachedResponse = JSON.parse(localStorage.getItem('transactionalAssistantMessage') || '{}') as any;
  const body = JSON.stringify({
    message: messageJSONString,
    temperature: 1,
    threadId: threadId
  });
  const key = makeKey(body);
  const response = cachedResponse[key];
  if (response) {
    return JSON.parse(response);
  }
  const promise = new Promise(async (resolve, reject) => {
    const response = await fetch(`${apiDomain}/api/${assistantName}`, {
      cache: 'no-store',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        message: messageJSONString,
        temperature: 1,
        threadId: threadId
      }),
    });

    const data = await response.json();

    if (!data.message) {
      return reject('Unhandled error');
    }
    cachedResponse[key] = data.message;
    localStorage.setItem('transactionalAssistantMessage', JSON.stringify(cachedResponse));

    try {
      resolve(JSON.parse(data.message));
    } catch {
      resolve(data.message);
    }
  });

  return promise;
}

/**
* Used to create a streaming call to an assistant
* Valid for conversationall-assistant
* Returns a promise
* */
export async function streamingAssistantMessage(assistantName: string, messageJSONString: string, threadId: string, onUpdate: (messageValue: any) => void, onEnd: () => void) {
  if (assistantName !== 'more-about-you-assistant') return Promise.reject('Streaming assistant not found');

  const response = await fetch(`${apiDomain}/api/${assistantName}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      message: messageJSONString,
      temperature: 1,
      threadId: threadId
    }),
  });

  if (!response.body) {
    console.error('No response body');
    return;
  }

  const reader = response.body.getReader();
  const decoder = new TextDecoder();

  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      if (onEnd) onEnd();
      break;
    } else {
      const messageValue = decoder.decode(value);
      if (onUpdate) onUpdate(messageValue);
    }
  }
}

/**
 * Prepares the input for the Data Analysis Assistant
 * @returns A JSON-parsable string of the data analysis assistant input
 */
export function prepareDataAnalysisInput({
    answerProfession,
    answerAge,
    answerWeight,
    answerHeight,
    answerSleep,
    answerSleepRoutine,
    answerVigorousExercise,
    answerResistanceTraining,
    answerMovement,
    answerGrouping,
    answerPain,
    answerProtein,
    answerFibre,
    answerFluid,
    answerAlcohol,
    answerSmoking,
    answerNetwork,
    answerHelp,
    answerBMI,
} = {
    answerProfession: "Stay at home parent",
    answerAge: "26",
    answerWeight: "87kg",
    answerHeight: "6'5",
    answerSleep: "Sometimes",
    answerSleepRoutine: "Mostly",
    answerVigorousExercise: "Less than 3 times a week",
    answerResistanceTraining: "Daily",
    answerMovement: "Improve my flexibility",
    answerGrouping: "Solo",
    answerPain: "Frequently",
    answerProtein: "No",
    answerFibre: "Yes",
    answerFluid: "No",
    answerAlcohol: "Over 10 Servings",
    answerSmoking: "I quit less than 15 years ago",
    answerNetwork: "Yes",
    answerHelp: "Sometimes",
    answerBMI: "24.5"
  }
) {

  // Construct the LLM input as JSON
  return `
    { "questions": [
      { "question": "Type of profession",																"answer": "${answerProfession}" },
      { "question": "Age",																			"answer": "${answerAge}" },
      { "question": "Weight",																			"answer": "${answerWeight}" },
      { "question": "Height",																			"answer": "${answerHeight}" },
      { "question": "Do you get 7-8 hours of sleep per night?",										"answer": "${answerSleep}" },
      { "question": "Do you have a regular sleep routine?",											"answer": "${answerSleepRoutine}" },
      { "question": "How often do you get vigorous physical activity for at least 20-30 minutes?",	"answer": "${answerVigorousExercise}" },
      { "question": "How often do you do resistance training?",										"answer": "${answerResistanceTraining}" },
      { "question": "How would you like to improve your movement?",									"answer": "${answerMovement}" },
      { question": "Do you prefer to workout solo or in a group?",									"answer": "${answerGrouping}" },
      { "question": "How does pain impact your life?",												"answer": "${answerPain}" },
      { "question": "Do you get enough protein daily with your current diet?",						"answer": "${answerProtein}" },
      { "question": "Do you get enough fibre in your diet?",											"answer": "${answerFibre}" },
      { "question": "Do you drink enough fluid daily?",												"answer": "${answerFluid}" },
      { "question": "How many servings of alcohol do you drink in a week?",							"answer": "${answerAlcohol}" },
      { "question": "Do you smoke or vape?",															"answer": "${answerSmoking}" },
      { "question": "Do you have a strong support network or community around you?",					"answer": "${answerNetwork}" },
      { "question": "Do you feel comfortable asking for help when you need it?",						"answer": "${answerHelp}" },
      { "question": "BMI",																			"answer": "${answerBMI}" }
    ]
  }`;
}

/**
 * Prepares the input for the Product Recommendation Assistant
 * @returns A JSON-parsable string of the data analysis assistant input
 */
export function prepareProductRecommendationInput({
  dataAnalysisInsights,
  moreAboutYouSummary,
} = { dataAnalysisInsights: [
    "Consider improving your sleep quality by aiming for 7-8 hours consistently. A regular sleep routine can significantly enhance overall wellbeing, and sufficient sleep is crucial for recovery and mental health.",
    "Increasing the frequency of vigorous physical activity can contribute positively to your fitness levels and overall health. Aim to integrate at least 150 minutes of moderate aerobic activity or 75 minutes of vigorous activity each week to maintain a healthy weight and improve cardiovascular health.",
    "Enhancing your protein intake might be beneficial as it plays a vital role in muscle repair and overall health. Incorporate more protein-rich foods into your diet to meet your daily requirements, especially if you are engaged in daily resistance training. Additionally, increase your fluid intake to stay hydrated, particularly if you exercise regularly."
  ],
  moreAboutYouSummary: "The client has expressed three primary struggles related to health and lifestyle. Firstly, they are finding it difficult to get up in the morning, likely due to issues with sleep quality. Secondly, they are concerned about their breathing during sleep, which is causing them to experience fitful rest. Lastly, the client mentioned feeling the need to drink alcohol when socializing, which leads to them consuming more than they would like."
}
) {

  return `
    {
      "factors": [
        "${moreAboutYouSummary}",
        "${dataAnalysisInsights.join('","')}"
      ]
    }
  `;
}

export function prepareGoalsInput({
  insights,
  moreAboutYouSummary,
}: {
    insights: {
      sleep_insights: string[],
      exercise_fitness_insights: string[],
      diet_nutrition_insights: string[],
      wellness_insights: string[],
    },
    moreAboutYouSummary: string
  }) {
  return JSON.stringify({
    insights,
    more_about_you_summary: moreAboutYouSummary,
  });
}


import type Gsap from 'gsap';
import $ from 'jquery';

declare var gsap: typeof Gsap;
declare var window: any;

let height = 0;
$(() => {
  if ($('.service').length == 0) {
    return;
  }

  $('.content-section.services .service').each(function(index, element) {
    $(element).css('zIndex', 20 + index);
    height += $(element).height()!;
  });

  // onMobileScreen(() => {
  //   $('.butterfly-wrapper-desktop').hide();
  //   $('.butterfly-wrapper-mobile').show();
  //   $('.content-section.services').removeClass('sticky').css('height', '');
  //   $('.content-section.services .service').css('transform', 'none');
  //   $('.clip-path-wrapper').css('clip-path', `inset(0px 0%)`);
  //   window.removeEventListener('servicesScrollProgress', animateServices);
  // }, () => {
  //   $('.butterfly-wrapper-desktop').show();
  //   $('.butterfly-wrapper-mobile').hide();
  //   $('.content-section.services .service').css('transform', 'translateX(100%)');
  //   $('.content-section.services .service:first').css('transform', 'none');
  //   $('.content-section.services').css('height', height);
  //   window.removeEventListener('servicesScrollProgress', animateServices);
  //   window.addEventListener('servicesScrollProgress', animateServices);
  // });

  // $('.butterfly-wrapper-desktop').show();
  $('.butterfly-wrapper-mobile').hide();
  $('.content-section.services .service').css('transform', 'translateX(100%)');
  $('.content-section.services .service:first').css('transform', 'none');
  $('.content-section.services').css('height', height);
  window.removeEventListener('servicesScrollProgress', animateServices);
  window.addEventListener('servicesScrollProgress', animateServices);

  $('.services .butterfly-wrapper-desktop .butterfly').on('mousemove', function(e) {
    if (window.innerWidth > 900) {
      var t = e.target.getBoundingClientRect();
      gsap.to(e.target, {
        x: (e.clientX - t.x - t.width / 2) / 10,
        y: (e.clientY - t.y - t.height / 2) / 10,
        duration: 0.1
      });
    }
  }).
    on('mouseout', function(e) {
      gsap.to(e.target, {
        x: 0,
        y: 0,
        duration: 0.4,
      });
    });

});

const servicesCount = $('.content-section.services .service').length;
const serviceDuration = 1 / (servicesCount + 1);
const stickyPercentage = 20;
let cachedProgress = 0;
let currentProgress = 0;

function animateServices(e: any) {
  const direction = e.detail.progress - cachedProgress > 0 ? 1 : -1;
  cachedProgress = e.detail.progress;
  currentProgress = e.detail.progress - serviceDuration;
  const $butterfly = $(e.detail.target).find('.butterfly-wrapper-desktop .butterfly');

  const currentServiceIndex = Math.floor(currentProgress / serviceDuration);

  if (currentProgress < 0 && currentServiceIndex < 0) {
    const clipPathPercentage = (Math.abs(currentProgress / serviceDuration) * 100) - 20;
    if (clipPathPercentage < 10) {
      $(e.detail.target).find('.clip-path-wrapper').not('.disable-animation').css('clip-path', `inset(0px ${clipPathPercentage}% round ${Math.ceil(clipPathPercentage)}px)`);
    } else if (clipPathPercentage > 0) {
      $(e.detail.target).find('.clip-path-wrapper').not('.disable-animation').css('clip-path', `inset(0px ${clipPathPercentage}% round 20px)`);
    } else {
      $(e.detail.target).find('.clip-path-wrapper').not('.disable-animation').css('clip-path', `inset(0px 0%)`);
    }
  } else {
    $(e.detail.target).find('.clip-path-wrapper').css('clip-path', `inset(0px 0%)`);
  }

  const stickyDuration = stickyPercentage / 100 * serviceDuration;
  const nextProgress = currentProgress + (serviceDuration - stickyDuration);
  const nextServiceIndex = Math.floor(nextProgress / serviceDuration);

  const currentService = $(e.detail.target).find('.service').get(currentServiceIndex)!;
  const nextService = $(e.detail.target).find('.service').get(nextServiceIndex)!;

  const travelledProgress = (nextProgress % serviceDuration) / serviceDuration * 100;
  if (currentServiceIndex >= 0 && nextServiceIndex > 0) {
    let foregroundImage = '';
    if ((travelledProgress > 50 && direction > 0)) {
      foregroundImage = $(nextService).data('foregroundImage');
    } else if ((travelledProgress < 50 && direction < 0)) {
      foregroundImage = $(currentService).data('foregroundImage');
    }
    if (foregroundImage) {
      $butterfly.css('background-image', `url(${foregroundImage})`);
    }
    if (nextService && travelledProgress > stickyPercentage && travelledProgress < (100 - stickyPercentage)) {
      $(nextService).css('visibility', 'visible');
      $(nextService).css('transition', 'transform ease 0.1s');
      const travelledPercentage = (travelledProgress - stickyPercentage) / (100 - stickyPercentage * 2) * 100;
      $(nextService).css('transform', `translateX(${100 - travelledPercentage}%)`);
    } else if (travelledProgress > (100 - stickyPercentage)) {
      $(nextService).css('transform', `translateX(0)`);
    } else {
      $(nextService).css('transform', `translateX(100%)`);
    }
  }

  if (!$(e.detail.target).hasClass('sticky')) {
    if (currentServiceIndex >= 0 && currentServiceIndex < (servicesCount - 1)) {
      $(e.detail.target).addClass('sticky');
      $(e.detail.target).find('.butterfly-wrapper-desktop').show();
      $(e.detail.target).find('.butterfly-wrapper-mobile').hide();
      $(e.detail.target).find('.butterfly-wrapper-desktop .butterfly-wrapper').css('top', '50vh');
    } else {
      $(e.detail.target).find('.butterfly-wrapper-desktop').hide();
      $(e.detail.target).find('.butterfly-wrapper-mobile').show();
      $(e.detail.target).find('.butterfly-wrapper-desktop .butterfly-wrapper').css('top', '');
    }
  } else {
    if (currentServiceIndex < 0 || currentServiceIndex >= (servicesCount -1)) {
      $(e.detail.target).removeClass('sticky');
      $(e.detail.target).find('.butterfly-wrapper-desktop').hide();
      $(nextService).find('.butterfly-wrapper-mobile').show();
    } else {
      $(e.detail.target).find('.butterfly-wrapper-desktop').show();
      $(e.detail.target).find('.butterfly-wrapper-mobile').hide();
      $(e.detail.target).find('.butterfly-wrapper-desktop .butterfly-wrapper').css('top', '50vh');
    }
  }

}

import type Gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

let gsap: typeof Gsap = (window as any).gsap as typeof Gsap;

if (typeof gsap !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
}

export default gsap;

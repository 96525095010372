import $ from 'jquery';

let toastTimeout: any;
$(function(){
  if($('.notification-wrapper').length === 0) {
    return;
  }

  setTimeout(() => {
    const navHeightBottom = $('.nav-bottom').outerHeight() || 20;
    $('.notification-wrapper').find('#toast-warning').css('bottom', navHeightBottom + 10); 
  }, 200);

  $('.toast-close-btn').on('click', function() {
    hideToast();
  });
});

function showToast() {
  if ($('.notification-wrapper').length === 0) {
    return;
  }

  $('.notification-wrapper').find('#toast-warning').addClass('active');

  clearTimeout(toastTimeout);
  toastTimeout = setTimeout(() => {
    hideToast();
  }, 4000);
}

function hideToast() {
  if ($('.notification-wrapper').length === 0) {
    return;
  }

  $('.notification-wrapper').find('#toast-warning').removeClass('active');
  clearTimeout(toastTimeout);
}

export { showToast, hideToast };

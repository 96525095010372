import $ from 'jquery';

$(function() {
  const form = $('.umbraco-forms-form.contactform');

  if (form.length === 0) {
    return;
  }

  form.each((_, e) => {
    const btn = $(e).find('.umbraco-forms-navigation').last();
    btn.addClass('btn-inline');
  });
});

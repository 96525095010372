import type Chartjs from 'chart.js/auto';
import $ from 'jquery';
import { prepareDataAnalysisInput, transactionalAssistantMessage, prepareProductRecommendationInput, prepareGoalsInput } from './agent-call';
import { convertTextToAlias, convertHeightToInches } from './util';

let Chart: typeof Chartjs = (window as any).Chart;

const loader = document.querySelector('.loader-wrapper');
$(async () => {
  if ($('.form-results').length == 0) {
    return;
  }
  const currentHash = window.location.hash;
  const hashDiv = $(currentHash);

  const currentUrl = window.location.href;
  const hash = new URL(currentUrl).hash;
  if (hash.startsWith('#result-category-')) {
    getDataAnalysisInsights(hash);
  }

  if (hashDiv.length > 0 && hashDiv.hasClass('content-wrapper')) {
    $('.form-results .content-wrapper').hide();
    hashDiv.removeClass('hidden').show();
    highlightMenuItem(currentHash);
  }
  $('.monarch-index-navigation a').on('click', function(e) {
    e.preventDefault();
    e.stopPropagation();
    const target = $(this).attr('href') as string;
    if (target) {
      const hrefParts = target.split(/(?=\/#)/);

      if (hrefParts.length > 1 || target === '/') {
        window.location.href = target;
        return false;
      }
    }

    window.location.hash = $(this).attr('href') || '';
    window.history.pushState(null, '', $(this).attr('href'));

    if ($(target).length > 0) {
      $('.form-results .content-wrapper').hide();
      $(target).removeClass('hidden').show();
      highlightMenuItem(target);
    }
  });
  if ($('.result-book-a-tour-wrapper form input.btn-next').length == 0 && $('.result-book-a-tour-wrapper form input.btn-submit').length == 0) {
    $('.result-book-a-tour-wrapper .monarch-index-navigation a.next-btn').on('click', function() {
      window.location.href = '/';
    });
  }
  if ($('.result-book-a-tour-wrapper form input.btn-next').length > 0) {
    $('.result-book-a-tour-wrapper .monarch-index-navigation a.next-btn').text($('.result-book-a-tour-wrapper form input.btn-next').val() as string);
    $('.result-book-a-tour-wrapper .monarch-index-navigation a.next-btn').on('click', function() {
      $('.result-book-a-tour-wrapper form input.btn-next').trigger('click');
    });
  }
  if ($('.result-book-a-tour-wrapper form input.btn-submit').length > 0) {
    $('.result-book-a-tour-wrapper .monarch-index-navigation a.next-btn').text($('.result-book-a-tour-wrapper form input.btn-submit').val() as string);
    $('.result-book-a-tour-wrapper .monarch-index-navigation a.next-btn').on('click', function() {
      $('.result-book-a-tour-wrapper form input.btn-submit').trigger('click');
    });
  }
  $('.result-book-a-tour-wrapper .application-form form').attr('action', $('.result-book-a-tour-wrapper .application-form form').attr('action') + '#book-a-tour');
  const formScores = calculateScores();
  const formData = getFormData();
  $('.result-book-a-tour-wrapper .firstname input').val(formData?.aboutyou?.name);
  $('.result-book-a-tour-wrapper .email input').val(formData?.aboutyou?.email);
  if (!formScores || formScores.forms.length == 0 || !formData.motivation) {
    $('.monarch-result-header').hide();
    $('.content-wrapper').hide();
    $('#monarch-index-noresult').show();
  } else {
    $('#monarch-index-noresult').hide();
    $('.monarch-result-header').show();
    const totalScore = formScores.scores.reduce((total, score: any) => total + score.score, 0);
    $('.form-result-list .form-image-wrapper').get().forEach((element, index) => {
      const $score = $(element).find('.score')
      const score = formScores.scores[index]?.percentage || 0;
      $score.text(score.toFixed(2) + '%')
      $(element).find('.percentage-mask').css('height', `${(100 - score)}%`);
    });
    $('.result-category-wrapper .score-wrapper').get().forEach((element, index) => {
      const $score = $(element).find('.score')
      const score = formScores.scores[index]?.percentage || 0;
      $score.text(score.toFixed(2) + '%')
      $(element).find('.percentage-mask').css('width', `${(100 - score)}%`);
    });
    $('.percentage-number').text(totalScore.toFixed(2));
    new Chart(
      (document.getElementById('form-results-chart') as HTMLCanvasElement).getContext('2d') as any,
      {
        type: 'radar',
        data: {
          labels: formScores.forms,
          datasets: [
            {
              label: 'Score',
              data: formScores.scores.map(e => e.percentage),
              fill: true,
              backgroundColor: 'rgba(255, 153, 52, 0.10)',
              borderColor: '#E57300',
              pointBackgroundColor: '#E57300',
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: 'rgb(255, 99, 132)'
            }
          ]
        },
        options: {
          elements: {
            line: {
              borderWidth: 3
            }
          },
          scales: {
            r: {
              angleLines: {
                display: false
              },
              min: 0,
              max: 100,
              pointLabels: {
                display: false // Hides the labels around the radar chart
              },
              ticks: {
                display: false, // Hides the labels in the middel (numbers)
                stepSize: 100,
              }
            }
          },
          plugins: {
            legend: {
              display: false,
            }
          }
        },
      } as any,
    );
  }

  if ($('.monarch-result-header').length > 0 && $('.monarch-result-header').is(':visible')) {
    try {
      loader?.classList.add('is-show');
      const insights = await dataAnalysis();
      const moreAboutYouSummary = localStorage.getItem('moreAboutYouSummary') || "";
      await goals(insights, moreAboutYouSummary);
      loader?.classList.remove('is-show');
    } catch (error) {
      console.log(error);
      loader?.classList.remove('is-show');
    } finally {
      loader?.classList.remove('is-show');
    }
  }

  (window as any).addEventListener("hashchange", function(e: any) {
    const url = e.newURL;
    const currentUrl = e.oldURL;
    const target = new URL(url).hash;

    if (currentUrl !== url && target.startsWith('#result-category-')) {
      getDataAnalysisInsights(target);
    }
  });
});

function getDataAnalysisInsights(target: string) {
  const dataAnalysisInsights = $(target).find('.analysises .result-text').get().map(e => $(e).text());
  productRecommendation($(target), {
    dataAnalysisInsights,
  });
}

export function getFormData(): any {
  const stringData = localStorage.getItem('formData');
  if (!stringData) {
    return null;
  }
  try {
    const parsedData = JSON.parse(stringData);
    if (!parsedData.aboutyou) {
      return null;
    }
    parsedData.aboutyou.proteinIntake = Math.round(parsedData.aboutyou.weight / 2.205 * 2);
    if (parsedData.dietnutrition) {
      const heightInches = convertHeightToInches(parsedData.aboutyou.height);
      const bmi = parsedData.aboutyou.weight / (heightInches ** 2) * 703;
      parsedData.dietnutrition.bmi = bmi;

    }
    return parsedData;
  } catch {
    return null;
  }
}

async function dataAnalysis() {
  const parsedData = getFormData();
  if (!parsedData) {
    return;
  }
	const message = prepareDataAnalysisInput({
    answerProfession: parsedData.aboutyou.typeofprofession,
    answerAge: parsedData.aboutyou.age,
    answerWeight: parsedData.aboutyou.weight + 'lb',
    answerHeight: parsedData.aboutyou.height + '(feet, in)',
    answerSleep: parsedData.sleep.sleep,
    answerSleepRoutine: parsedData.sleep.sleepRoutine,
    answerVigorousExercise: parsedData.exercisefitness.vigorousExercise,
    answerResistanceTraining: parsedData.exercisefitness.resistanceTraining,
    answerMovement: parsedData.exercisefitness.movement,
    answerGrouping: parsedData.exercisefitness.grouping,
    answerPain: parsedData.exercisefitness.pain,
    answerProtein: parsedData.dietnutrition.protein,
    answerFibre: parsedData.dietnutrition.fibre,
    answerFluid: parsedData.dietnutrition.fluid,
    answerAlcohol: parsedData.dietnutrition.alcohol,
    answerSmoking: parsedData.dietnutrition.smoking,
    answerNetwork: parsedData.wellness.network,
    answerHelp: parsedData.wellness.help,
    answerBMI: `${parsedData.dietnutrition.bmi}`,
  });
	const result = await transactionalAssistantMessage('data-analysis-assistant', message);
  console.log("Results of Data Analysis >>", result);
  Object.entries(result).forEach(([key, value]) => {
    const suggestions = value as string[];
    const $section = $('.analysises.' + key);
    if (suggestions.length > 0) {
      $section.find('.template').hide();
      $section.append(`<div class="result-text">${suggestions.join('<br>')}</div>`);
    } else {
      $section.find('.template').show();
    }
  });
  return result;
}

/**
 * Triggers a Product Recommendation Assistant message
 * Waits for a promise to resolve with a callback whose result is an array of insights that can be printed to the results page.
 *    - Format: { "suggested_products": ["Suggestion 1", "Suggestion 2", ...] }
 */
async function productRecommendation($target: any, {
  dataAnalysisInsights
}: {
    dataAnalysisInsights: string[]
}) {
  const moreAboutYouSummary = localStorage.getItem('moreAboutYouSummary') || "";
	const message = prepareProductRecommendationInput({
    dataAnalysisInsights: dataAnalysisInsights || [],
    moreAboutYouSummary: moreAboutYouSummary || "",
  });
  let result;

  try {
    loader?.classList.add('is-show');
    result = await transactionalAssistantMessage('products-assistant', message);
    console.log("Results of product recommendation >>", result);
    $target.find('.suggestions .suggestion:not(.template)').remove();
    console.log('products-assistant', result.suggested_products);
    if (result.suggested_products.length > 0) {
      result.suggested_products.forEach((product: any) => {
        const template = $target.find('.suggestions .suggestion.template').clone().get(0) as HTMLElement;
        $(template).find('.suggestion-title span').text(product.product_name)
        $(template).find('.suggestion-text').text(product.reason);
        $(template).removeClass('hidden').removeClass('template');
        $target.find('.suggestions').append($(template));
      });
      $target.find('.suggestions .suggestion').show();
      $target.find('.suggestions .suggestion.template').hide();
    } else {
      $target.find('.suggestions .suggestion.template').show();
    }
  } catch (error) {
    console.log(error);
    loader?.classList.remove('is-show');
  } finally {
    loader?.classList.remove('is-show');
  }
}

function calculateScores() {
  const forms = $('.form-result-list .form-title').get().map((e) => $(e).text());
  const scores = [];
  const parsedData = getFormData();
  if (!parsedData) {
    return null;
  }
  for (const form of forms) {
    const alias = convertTextToAlias(form);
    const score = calculateScore(alias, parsedData[alias]);
    scores.push(score);
  }
  return {
    forms,
    scores,
  };
}

function calculateScore(alias: string, data: any) {
  let score = 0;
  let percentage = 0;
  if (!data) {
    return {
      score,
      percentage,
    };
  }
  let scoreData: any = {};
  if (alias == 'sleep') {
    scoreData = {
      sleep: {
        'yes': 12.5,
        'mostly': 10,
        'sometimes': 6,
      },
      sleeproutine: {
        'yes': 12.5,
        'mostly': 10,
      },
    };
  } else if (alias == 'exercisefitness') {
    scoreData = {
      vigorousexercise: {
        'daily': 8.33,
        'mostly everyday': 8.33,
        'less than 3 times a week': 4,
      },
      resistancetraining: {
        'daily': 8.33,
        'mostly everyday': 8.33,
        'less than 3 times a week': 4,
      },
      pain: {
        'not at all': 8.34,
        'sometimes': 8.34,
        'frequently': 3,
      }
    };
  } else if (alias == 'dietnutrition') {
    if (data.bmi) {
      if (data.bmi <= 18.5) {
        score += 2;
      } else if (18.5 < data.bmi && data.bmi <= 25) {
        score += 4.16;
      } else if (25 < data.bmi && data.bmi <= 30) {
        score += 2;
      }
    }
    scoreData = {
      protein: {
        'yes': 4.16,
      },
      fibre: {
        'yes': 4.16,
      },
      fluid: {
        'yes': 4.16,
      },
      alcohol: {
        'none': 4.16,
        '1-2 servings': 4.16,
        '3-10 servings': 2.5,
      },
      smoking: {
        'i never have': 4.2,
        'i quit less than 15 years ago': 3,
        'sometimes/socially': 2,
      },
    };
  } else if (alias == 'wellness') {
    scoreData = {
      network: {
        'yes': 12.5
      },
      help: {
        'yes': 12.5,
        'sometimes': 6,
      },
    };
  }
  Object.entries(data).forEach(([key, value]) => {
    score += (scoreData?.[key]?.[(value as any).toString().toLowerCase()] ?? 0);
  });
  percentage = (score / 25) * 100;
  return {
    alias,
    score,
    percentage,
  };
}

function highlightMenuItem(target: string) {
    const menuItem = $('.monarch-result-header').find('li a').get().find((a: any) => $(a).attr('href') === target);
    if (menuItem) {
      $(menuItem).parents('.monarch-result-header').find('li.active').removeClass('active');
      $(menuItem).parents('li').addClass('active');
    }
}


/**
 * Triggers a Goals Assistant message
 * Returns a promise whose result is an array of goals that can be printed to the results page.
 *    - Format: { "goals": ["Goal 1", "Goal 2", ...] }
 */
async function goals(insights: any, moreAboutYouSummary: string) {
	const message = prepareGoalsInput({
    insights,
    moreAboutYouSummary,
  }); // TODO: Fill with real data
	try {
    const result = await transactionalAssistantMessage('goals-assistant', message);
    if (result.goals.length > 0) {
      $(".membership-recommendation p:last").remove();
      result.goals.forEach((goal: string) => {
        $(".membership-recommendation").append(`<p class="goal-text">${goal}<br/><br/></p>`);
      });
    } else {
      $(".membership-recommendation p:last").show();
    }
  } catch (error) {
    console.log("ERROR", error);
  }
}


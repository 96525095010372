import $ from 'jquery';
import { streamingAssistantMessage, transactionalAssistantMessage } from '../../scripts/agent-call';
import { onDesktopScreen } from '../../scripts/util';

let threadId: any;

$(() => {
  $('#more-about-you-send').on('click', moreAboutYou);
  if ($('umbraco-forms-form.motivation form').length > 0) {
    $('.btn-continue').off('click').on('click', async function(e) {
      e.preventDefault();
      const $form = $('.umbraco-forms-form.motivation form');
      $(this).text('Submitting...');
      if (!threadId || $form.data('summary')) {
        $form.trigger('submit');
        return true;
      }
      e.stopPropagation();
      await summariseMoreAboutYou();
      return false;
    });
  }

  onDesktopScreen(() => {
    $('.gpt-chat-box').on("mousewheel DOMMouseScroll", function(e: any) {
      e.preventDefault();
      var scrollDistance: number = 20;
      var scrollTop = $('.gpt-chat-box').scrollTop() || 0;
      var delta: number = e.originalEvent.wheelDelta/120;
      var finalScroll = scrollTop - (delta * scrollDistance);
      gsap.to('.gpt-chat-box', {
        scrollTo: finalScroll,
        duration: 2
      });
    });
  });
});

function moreAboutYou(e: any) {
	const chatButton = e.target;
	const chatInput = document.querySelector("#user-message") as HTMLInputElement;
	const chatWindow = document.querySelector(".gpt-chat-box")! as HTMLDivElement;

	chatButton.disabled = true;
  $('.message.assistant').removeClass('current');

	// Add the user's message to the chat window
	const inputMessageValue = chatInput.value;
	const userChatMessage = createChatMessage(true, inputMessageValue);
	chatWindow.appendChild(userChatMessage);
	chatInput.value = "";

	// Add an assistant message to the chat window
	const assistantChatMessage = createChatMessage(false, "");
	chatWindow.appendChild(assistantChatMessage);

	scrollToBottom();

	// Trigger a streaming chat response
	streamingAssistantMessage('more-about-you-assistant', inputMessageValue, threadId, onUpdate, onEnd);

	// Creates a chat message div element
	function createChatMessage(userMessage = true, innerText = "") {
    const wrapper = document.createElement("div");
    wrapper.className = `flex ${userMessage ? 'justify-end' : 'justify-start'} mb-[1.2rem]`;
		const className = userMessage ? "message self" : "message assistant current";
		const div = document.createElement("div");
		div.className = className;
    div.innerText = innerText;
    wrapper.appendChild(div);
		return wrapper;
	}

	// Scrolls the chat window to the bottom
	function scrollToBottom() {
		chatWindow.scrollTop = chatWindow.scrollHeight + chatWindow.offsetHeight;
	}

	// Update the chat window with the response
	function onUpdate(update: string) {
		try {
			// If JSON, this is a complete response and we can get and store the threadId
			const result = JSON.parse(update);
			threadId = result.threadId;
		} catch (e) {
			// Else we have a non-JSON response, which is a regular update
			const lastChat = chatWindow.querySelector(".message.assistant.current") as HTMLDivElement;
			lastChat.innerText += update;
			scrollToBottom();
		}
	}

	// Enable the chat button when the streaming response is complete
	function onEnd() {
		chatButton.disabled = false;
	}
}

async function summariseMoreAboutYou() {
  const result = await transactionalAssistantMessage('more-about-you-assistant', "SUMMARISE", threadId)
  localStorage.setItem('moreAboutYouSummary', result);
  $('.umbraco-forms-form.motivation form').data('summary', result);
  $('.umbraco-forms-form.motivation form').trigger('submit');
}

import $ from 'jquery';

import { formatHTagLines } from './util';

$(() => {
  if ($('.application-banner').length == 0) {
    return;
  }

  formatHTagLines('.application-banner', 'h3');
});

import $ from 'jquery';
import textFit from 'textfit';

window.textFit = textFit;

export default class TextAnimationManager {
  private animationInterval = 2000;
  private resizeTimeout: any;

  constructor() {
    window.addEventListener('resize', this.onWindowResize.bind(this));
  }

  public startTextAnimations(
    selector: string,
    tag: string,
    maxFontSize: number,
    splitIntoLines: boolean,
    showSingleElementOnly: boolean = false
  ): void {
    let currentlyVisibleElementIndex: number = 0;
    const elements = $(selector);
    elements.each((index: number, el: any) => {
      const text = $(el).text();
      this.formatText(el, text, maxFontSize, tag, splitIntoLines);
      if (showSingleElementOnly && index !== currentlyVisibleElementIndex) {
        $(el).hide();
      }
    });

    if (!$(selector).hasClass('with-animated-titles')) {
      return;
    }

    setInterval(() => {
      this.toggleTextAnimation(selector, tag);
    }, this.animationInterval);

    if (showSingleElementOnly) {
      setInterval(() => {
        currentlyVisibleElementIndex++;
        currentlyVisibleElementIndex = (currentlyVisibleElementIndex + 1) % elements.length;
        $(selector).each((index, el) => {
          if (index == currentlyVisibleElementIndex) {
            $(el).show();
          } else {
            $(el).hide();
          }
        });
      }, this.animationInterval * 2);
    }
  }

  private toggleTextAnimation(selector: string, tag: string): void {
    $(`${selector} ${tag}.animating span`).each((_index: number, element: HTMLElement) => {
      this.resetAnimation(element);
      if ($(selector).hasClass('fade-out')) {
        $(element).css('transform', 'translateY(100%)');
      } else {
        $(element).css('transform', 'translateY(0)');
      }
    });
    $(selector).toggleClass('fade-out');
  }

  private resetAnimation(el: HTMLElement): void {
    el.style.animation = 'none';
    el.offsetHeight;
    el.style.animation = '';
  }

  private onWindowResize(): void {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(() => {
      $('.animated-title, .animated-subtitle').each((_, el) => {
        if ($(el).css('display') !== 'none') {
          textFit(el, { maxFontSize: parseInt($(el).css('font-size') ?? '16', 10), widthOnly: true });
        }
      });
    }, 300);
  }

  private formatText(el: HTMLElement, text: string, maxFontSize: number, tag: string, splitIntoLines = true) {
    let formattedText = '';
    const classes = $(el).children().attr('class');

    if (splitIntoLines) {
      const lines = text.split("|");
      const delay = 100;
      let index = 0;
      for (const line of lines) {
        const animationDelay = `${index * delay}ms`;
        const formatedLine = `<${tag} class="animating light ${classes}" style="--animation-delay: ${animationDelay}"><span>` + line.split(' ').join('</span> <span>') + `</span></${tag}>`;
        formattedText = `${formattedText}${formatedLine}`;
        index++;
      }
    } else {
      formattedText = `<${tag} class="animating light ${classes}"><span>` + text.split(' ').join('</span> <span>') + `</span></${tag}>`;
    }

    $(el).html(formattedText);
    textFit(el, { maxFontSize, widthOnly: true });
  }
}

import $ from 'jquery';
import 'jquery.cookie';

$(function(){
  if ($('.cookie-banner').length === 0) {
    return;
  }

  checkCookieConsent();

  $('.btn-reject-cookie').on('click', (e) => {
    e.preventDefault();
    setCookieConsent("reject_cookie");
  });

  $('.btn-allow-cookie').on('click', (e) => {
    e.preventDefault();
    setCookieConsent("accept_cookies");
  });
});

function checkCookieConsent() {
  let cookieConsent = $.cookie("reject_cookie") || $.cookie("accept_cookies");

  if (!cookieConsent) {
    $('.cookie-banner').css('display','block');
  }
}

function setCookieConsent(key: string) {
  switch (key) {
    case 'reject_cookie':
      $.cookie("reject_cookie", 'true', { path: '/' });
      localStorage.setItem('accept_cookies', 'false');
      break;
    case 'accept_cookies':
      $.cookie("accept_cookies", 'true', { path: '/', expires: 365 });
      localStorage.setItem('accept_cookies', 'true');
      break
    default:
      break;
  }
  $('.cookie-banner').css('display','none');
}

import $ from 'jquery';

import './index.scss';
import './scripts/header';
import './scripts/large-hero-banner';
import './scripts/introduction';
import './scripts/small-image-banner';
import './scripts/large-image-banner-multiple';
import './scripts/scroll';
import './scripts/ecosystem';
import './scripts/two-column';
import './scripts/util';
import './scripts/service';
import './scripts/membership';
import './scripts/tooltip';
import './scripts/comparison-table';
import './scripts/small-hero-banner';
import './scripts/application-banner';
import './scripts/carousel-images';
import './scripts/gsap';
import './scripts/modal';
import './scripts/member-list';
import './scripts/form';
import './scripts/cookie-banner';
import './scripts/form-results';
import './scripts/link';
import './scripts/fit-text';

// Pages
import './scripts/pages/page-about-us';
import './scripts/pages/page-locations';
import './scripts/pages/page-location-detail';

// Monarch Index
import './monarch/scripts/form-picker';
import './monarch/scripts/form-list';
import './monarch/scripts/more-about-you';
import './monarch/scripts/notification';

declare var window: any;

window['$'] = $;

import $ from 'jquery';
import 'web-dialog';
import { WebDialog } from 'web-dialog';

$(document).ready(() => {
  if (('[toggle="modal"]').length == 0) {
    return;
  }
  $('[toggle="modal"]').each((_index, button) => {
    const modalId = $(button).data('target');
    const container = $(modalId).get(0);
    if (button && container) {
      setupModal(button, container as HTMLElement);
    }
  });
});

function setupModal(button: HTMLElement, modal: HTMLElement) {
  function update() {
  }

  function showModal() {
    ($(modal).get(0) as WebDialog).show();
    update();
  }

  function hideModal() {
    ($(modal).get(0) as WebDialog).close();
  }

  function toggleModal(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    if ($(modal).attr('open')) {
      hideModal();
    } else {
      showModal();
    }
  }

  $(modal).append(`<button class="close-button">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <rect x="1.63672" y="0.22168" width="20" height="2" transform="rotate(45 1.63672 0.22168)" fill="#FDA650"/>
  <rect x="0.222656" y="14.3638" width="20" height="2" transform="rotate(-45 0.222656 14.3638)" fill="#FDA650"/>
  </svg>
</button>`);
  $(modal).find('.close-button').on('click', hideModal);

  $(button).on('click', toggleModal);
}

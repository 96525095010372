import $ from 'jquery';
import { getFormData } from '../../scripts/form-results';
import { showToast } from './notification';

$(function(){
  if($('.form-picker').length === 0) {
    return;
  }

  const keyFormStore = 'formData';

  $('.btn-continue').on('click', function(e) {
    e.preventDefault();
    const submitButton = $('.umbraco-forms-navigation input[type="submit"].btn-submit');

    if (submitButton.length > 0) {
      if ($('.page-more-about-you').length > 0) {
        if (isFullData()) {
          $('form').trigger('submit');
        } else {
          showToast();
        }
      } else {
        $('form').trigger('submit');
      }
    }
  });

  const parsedData = getFormData();
  if (parsedData?.aboutyou?.email) {
    $('input[data-field="Email"]').val(parsedData.aboutyou.email);
  }
  if (parsedData?.aboutyou?.proteinIntake) {
    let text = $('.protein .umbraco-forms-legend').text();
    text = text.replace('{{proteinIntake}}', parsedData.aboutyou.proteinIntake.toString());
    $('.protein .umbraco-forms-legend').text(text);
  }

  $('form').on('submit', function(e) {
    e.preventDefault();
    let storedData = localStorage.getItem(keyFormStore);
    let parsedData: { [key: string]: any } = storedData ? JSON.parse(storedData) : {};

    $('.umbraco-forms-form').each((_index, element) => {
      let data: { [key: string]: any } = {};
      const formKey = getKeyFormClass(element);
      const formsField = $(this).find('.umbraco-forms-field');

      if(formsField.length > 0) {
        formsField.each((_i, e) => {
          const key = getKeyFormClass(e);
          let value;
          const input = $(e).find('input, select, textarea');

          if (input.attr('type') === 'radio') {
            value = input.filter(':checked').val();
          } else if (input.attr('type') === 'checkbox') {
            value = input.filter(':checked').map(function() {
              return $(this).val();
            }).get();
          } else {
              value = input.val();
          }

          data[key] = value;
        })
      }
      parsedData[formKey] = data;
      localStorage.setItem(keyFormStore, JSON.stringify(parsedData));
    });

    (e.target as HTMLFormElement).submit();
  });

  initForm();
});

function getKeyFormClass(element: HTMLElement): string {
  const eClass = $(element).attr('class')?.trim().split(/\s+/) || [];
  return eClass[1] || '';
}

function initForm() {
  const formData = getFormData();
  if (!formData) return;

  $('.umbraco-forms-form').each((_index, form) => {
    const dataForm = formData[getKeyFormClass(form)];
    if (!dataForm) return;

    Object.entries(dataForm).forEach(([field, value]) => {
      $(form).find(`.${field} input`).each(function() {
        const $input = $(this);
        if ($input.is('[type="radio"], [type="checkbox"]') && $input.val() === value) {
          $input.prop('checked', true);
        } else if ($input.is('[type="text"]')) {
          $input.val(String(value));
        }
      });
    });
  });
}

function isFullData(): boolean {
  const storedData = localStorage.getItem('formData');
  let parsedData: { [key: string]: any } = storedData ? JSON.parse(storedData) : {};
  const requiredSections = ['wellness', 'sleep', 'exercisefitness', 'dietnutrition'];
  const hasRequiredSections = requiredSections.every(section => section in parsedData);

  if (hasRequiredSections) {
    return true;
  }

  return false;
}

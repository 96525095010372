import LocomotiveScroll from 'locomotive-scroll';
import { isMobile } from './util';

function onScroll({ direction, animatedScroll }: any) {
  if (!isMobile()) {
    if (direction > 0) {
      $('.header').addClass('slideUp');
      $('.has-sub-menu').addClass('hidden-sub');
    } else {
      $('.header').removeClass('slideUp');
      $('.has-sub-menu').removeClass('hidden-sub');
    }

    if($('.index-button').length > 0) {
      if (animatedScroll > 0) {
        $('.index-button-content').addClass('scaleDown');
      } else {
        $('.index-button-content').removeClass('scaleDown');
      }
    }
  }

  return true;
}

new LocomotiveScroll({
  scrollCallback: onScroll,
  lenisOptions: {
    isSmooth: true,
  },
});
